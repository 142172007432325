import { Divider } from 'juhuui';

const Line = Divider.with({
  m: 0,
  opacity: '1',
  borderTop: '1px solid tom.line',
  borderColor: 'tom.line',
});

export default Line;
