import { Stack } from 'juhuui';
import React from 'react';

import { ContainerOuter, grid12, HeadingLg, TextSm } from '../styles';
import Address from './address';
import OpeningHours from './openingHours';

interface Props {
  intro: string;
}

function Subheader({ intro = '', address = false, css }: Props) {
  return (
    <ContainerOuter
      as="header"
      className={`subheader ${grid12}`}
      css={{
        margin: 'auto',
        maxW: 'var(--max-width)',
        position: ['relative', undefined, 'sticky'],
        pt: ['10', undefined, '16'],
        px: 'var(--px)',
        top: [undefined, undefined, '20'],
        ...css,
      }}
      itemScope=""
      itemType="http://schema.org/Physiotherapy"
    >
      <HeadingLg
        as="h1"
        classNames="text-text col-span-12 lg:col-span-9 m-0 pr-[5%] md:pr-[10%] lg:pr-[23%] whitespace-pre-wrap"
        // css={{
        //   color: 'text',
        //   column: ['1/span 12', undefined, '1/span 9'],
        //   m: '0',
        //   pr: ['5%', '10%', '23%'],
        //   whiteSpace: 'pre-wrap',
        // }}
      >
        {intro}
      </HeadingLg>
      {address && (
        <Stack
          css={{
            pt: ['10', undefined, '2'],
            column: ['1/span 12', undefined, '10/span 3'],
            direction: 'column',
          }}
          divideEqual={false}
          gap={['8', undefined, '6']}
        >
          <TextSm as="div">
            <Address />
          </TextSm>
          <OpeningHours />
        </Stack>
      )}
    </ContainerOuter>
  );
}

export default Subheader;
