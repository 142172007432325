import { Box, Heading, Text } from 'juhuui';

// 18px
export const TextSm = Box.as('p').with({
  lineHeight: ['1.2'],
  fontSize: ['sm', 'xs'],
});

// 24px
export const TextMd = Box.as('p').with({
  fontSize: ['md'],
});

// 32 px
export const TextLg = Box.as('p').with({
  lineHeight: ['1.1'],
  fontSize: ['lg', 'lg', 'xl'],
  hyphens: 'auto',
});

// 52px
export const TextXl = Box.as('p').with({
  lineHeight: ['1'],
  fontSize: ['xl', '2xl', '3xl'],
});
