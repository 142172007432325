import { Link as GLink } from 'gatsby-plugin-react-i18next';
import { Box } from 'juhuui';
import React from 'react';

import { TextSm } from '../styles';

interface ButtonProps {
  text: string;
  to?: string;
  [x: string]: any;
}

export function Button({ text, children, css, ...props }: ButtonProps) {
  return (
    <Box
      as="a"
      css={{
        align: 'center',
        bg: 'tom.bgButton',
        c: 'tom.textInverted',
        '&:hover': {
          bg:  'tom.text',
        },
        '&:active': {
          transform: 'scale(0.96)',
        },
        px: [3, undefined, 4],
        py: [3, undefined, 2],
        rounded: 'sm',
        transition: 'all 0.15s ease-in-out',
        ...css,
      }}
      // border="1px solid currentColor"
      {...props}
    >
      <TextSm>{text || children}</TextSm>
    </Box>
  );
}

export default Button;
