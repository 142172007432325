import { graphql, useStaticQuery } from 'gatsby';
import {
  Link as GLink,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import { Box } from 'juhuui';
import React from 'react';

import { getLocalizationObj } from '../utilities/getLocalizationObj';

interface Props {
  [x: string]: any;
}

function Address(props: Props) {
  const { language } = useI18next();
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query AddressQuery {
      strapi {
        fact {
          localizations {
            city
            plz
            street
            description
          }
          city
          plz
          street
          description
        }
      }
    }
  `);
  const { city, plz, street, description } =
    getLocalizationObj(data.strapi.fact, language) || {};

  if (!plz || !city || !street) return;

  return (
    <Box className="address" {...props}>
      <Box as="p" css={{ d: 'block' }}>
        mikromakro
      </Box>
      <div>{description}</div>
      <address
        itemScope
        itemProp="address"
        itemType="http://schema.org/PostalAddress"
      >
        <Box as="p" css={{ d: 'block' }} itemProp="streetAddress">
          {street}
        </Box>
        <div>
          <span itemProp="postalCode">{plz}</span>
          <span itemProp="addressRegion"> {city}</span>
        </div>
      </address>
    </Box>
  );
}

export default Address;
