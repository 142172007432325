/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import './layout.css';

import { graphql, useStaticQuery } from 'gatsby';
import { Box, Flex } from 'juhuui';
import React, { ReactNode } from 'react';

import Footer from './footer';
// import { grid12 } from '../styles';
import Header from './header';
// import Modal from './modal';

interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children, footer }: LayoutProps) => {
  const { onlyNav } = footer || {};
  const data = useStaticQuery(graphql`
    query SiteTitle2Query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Flex
      css={{
        align: 'center',
        bg: 'tom.bg',
        direction: 'column',
        justify: 'space-between',
        minHeight: '100vh',
      }}
    >
      <Header siteTitle={data.site.siteMetadata.title} />
      <Box as="main" css={{ w: 'full' }}>
        {children}
      </Box>
      <Footer onlyNav={onlyNav} />
    </Flex>
  );
};

export default Layout;
