/* eslint-disable no-restricted-syntax */
import { Heading } from 'juhuui';
import React from 'react';

const DEFAULT_HEADING_STYLES = 'leading-shorter';

export const HeadingSm = (props: any): any => {
  const { as, classNames, ...rest } = props;

  const Tag = as || (`h4` as keyof JSX.IntrinsicElements);

  const componentClassNames =
    'mb-0 leading-[1.2] text-sm md:text-xs font-[inherit]';

  return (
    <Tag
      className={`${DEFAULT_HEADING_STYLES} ${componentClassNames} ${classNames}`}
      {...rest}
    />
  );
};

// 24px
export const HeadingMd = (props: any) => {
  const { css, as, classNames, ...rest } = props;
  const Tag = as || (`h3` as keyof JSX.IntrinsicElements);

  const componentClassNames = 'mb-0 text-md font-[inherit]';

  return (
    <Tag
      className={`${DEFAULT_HEADING_STYLES} ${componentClassNames} ${classNames}`}
      {...rest}
    />
  );
};

// 40px
export const HeadingLg = (props: any) => {
  const { css, as, classNames, ...rest } = props;
  const Tag = as || (`h2` as keyof JSX.IntrinsicElements);

  const componentClassNames =
    'mb-0 leading-[1.1] text-lg md:text-lg lg:text-xl font-[inherit]';

  return (
    <Tag
      className={`${DEFAULT_HEADING_STYLES} ${componentClassNames} ${classNames}`}
      {...rest}
    />
  );
};

// 52px
// export const HeadingXl = Heading.with({
//   as: 'h2',
//   mb: '0',
//   lineHeight: ['1'],
//   fontSize: ['xl', '2xl', '3xl'],
//   fontFamily: 'inherit',
// });
