import { graphql, useStaticQuery } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'juhuui';
import React from 'react';

import { TextSm } from '../styles';
import { getLocalizationObj } from '../utilities/getLocalizationObj';

interface OpeningHoursProps {
  timeOnly?: boolean;
  [x: string]: any;
}

function OpeningHours({ timeOnly = false, ...props }: OpeningHoursProps) {
  const { language } = useI18next();
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query OpeningHoursQuery {
      strapi {
        fact {
          localizations {
            openingHours
            phone
          }
          openingHours
          phone
        }
      }
    }
  `);
  const { openingHours, phone } =
    getLocalizationObj(data.strapi.fact, language) || {};

  /** TODO: Add opening Hours to item prop */
  return (
    <TextSm as="div" className="opening-hours" {...props}>
      <div>{timeOnly ? t('consultation hour') : t('book an appointment')}</div>
      {!timeOnly && (
        <Link css={{ d: 'block' }} href={`tel:+49${phone}`}>
          {t('at')} {phone}
        </Link>
      )}
      <data itemProp="openingHours" value={openingHours}>
        {openingHours}
      </data>
    </TextSm>
  );
}

export default OpeningHours;
