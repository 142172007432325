import { graphql, useStaticQuery } from 'gatsby';
import { Link as GLink, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { Box, Flex, Link } from 'juhuui';
import React from 'react';

import Address from '../components/address';
import { Button } from '../components/button';
import { grid12, grid6, GridHalfFull, TextLg, TextMd, TextSm } from '../styles';
import { getLocalizationObj } from '../utilities/getLocalizationObj';

const routes = {
  en: {
    services: 'leistungen',
    about: 'praxis',
    contact: 'kontakt',
    privacy: 'datenschutz',
    impressum: 'impressum',
    careers: 'karriere',
  },
  de: {
    leistungen: 'services',
    praxis: 'about',
    kontakt: 'contact',
    datenschutz: 'privacy',
    impressum: 'impressum',
    karriere: 'careers',
  },
};

function Footer({ onlyNav }) {
  const { language, originalPath } = useI18next();
  const { t } = useTranslation();

  const newPath = originalPath
    .split('/')
    .map((str, index) => {
      return index === 1 ? routes[language][str] : str;
    })
    .join('/');

  const data = useStaticQuery(graphql`
    query FooterQuery {
      strapi {
        fact {
          localizations {
            openingHours
            phone
            email
            googleMapsLink
          }
          openingHours
          phone
          email
          googleMapsLink
        }
        footer {
          images {
            caption
            url
            file {
              ...Image
            }
          }
        }
      }
    }
  `);
  const { phone, openingHours, email, googleMapsLink } =
    getLocalizationObj(data.strapi.fact, language) || {};
  // const { images } = data.strapi.footer || {};

  return (
    <Box
      className="footer"
      css={{
        bg: 'tom.bgInverted',
        color: 'tom.textInverted',
        w: 'full',
      }}
    >
      {!onlyNav && (
        <Box
          className={grid12}
          css={{
            bg: 'tom.bgInverted',
            margin: 'auto',
            maxW: 'var(--max-width)',
            px: 'var(--px)',
            py: ['10'],
          }}
        >
          <GridHalfFull fw={{ first: true }}>
            <TextLg as="div" css={{ m: '0', p: '0' }}>
              <Box as="p">{t('book an appointment')}</Box>
              <Link
                css={{
                  display: 'block',
                }}
                href={`tel:+49${phone}`}
              >
                {t('at')} {phone}
              </Link>
              <Link
                css={{
                  display: 'block',
                }}
                href={`mailto: ${email}`}
              >
                {email}
              </Link>
            </TextLg>
          </GridHalfFull>
          <GridHalfFull>
            <Box className={grid6} css={{ py: ['8', '0'] }}>
              <TextMd
                as="div"
                css={{
                  column: [
                    '1/span 6',
                    undefined,
                    openingHours ? '1/span 3' : '1/span 6',
                  ],
                }}
              >
                <Address />
                <Flex>
                  <Button
                    css={{
                      bg: 'inherit',
                      border: '1px solid tom.textHighlight',
                      mt: [6],
                      '&:hover': {
                        bg: 'tom.textHighlight',
                      },
                    }}
                    href={googleMapsLink}
                    rel="noopener"
                    target="_blank"
                  >
                    {t('plan your journey')}
                  </Button>
                </Flex>
              </TextMd>
              {openingHours && (
                <TextMd
                  as="div"
                  css={{
                    column: ['1/span 6', undefined, '4/span 3'],
                    mt: ['6', undefined, '0px'],
                  }}
                >
                  <Box as="p">{t('consultation hour')}</Box>
                  <Box as="p">{openingHours}</Box>
                </TextMd>
              )}
            </Box>
          </GridHalfFull>
        </Box>
      )}
      <Box
        className={grid12}
        css={{
          margin: 'auto',
          maxW: 'var(--max-width)',
          px: 'var(--px)',
          pb: ['calc(6vh + 0.75rem)', undefined, 10],
          pt: ['16', undefined, onlyNav ? '10' : '32'],
        }}
      >
        <GridHalfFull fw={{ first: true }}>
          <TextSm>
            <Link
              as={GLink}
              css={{ '&:hover': { color: 'tom.textHighlight' }, pr: ['6'] }}
              to="/impressum/"
            >
              {t('imprint')}
            </Link>
            <Link
              as={GLink}
              css={{ '&:hover': { color: 'tom.textHighlight' }, pr: ['6'] }}
              to={`/${t('privacy link')}/`}
            >
              {t('privacy')}
            </Link>
            {/* <Link
                    as={GLink}
                    css={{ '&:hover': { color: 'tom.textHighlight' } }}
                    to="/cookies/"
                  >
                    Cookies
                  </Link> */}
          </TextSm>
        </GridHalfFull>
        <GridHalfFull>
          <TextSm>
            <Link
              as={GLink}
              css={{
                /* Active Style */
                color:
                  language === 'en' ? 'tom.textHighlight' : 'tom.textInverted',
                borderBottom:
                  language === 'en' ? '1px solid currentColor' : 'none',
                '&:hover': {
                  color:
                    language === 'en'
                      ? 'tom.textInverted'
                      : 'tom.textHighlight',
                },
                mr: ['6'],
              }}
              language="en"
              to={newPath}
            >
              {t('english')}
            </Link>
            <Link
              as={GLink}
              css={{
                /* In-Active Style */
                color:
                  language === 'de' ? 'tom.textHighlight' : 'tom.textInverted',
                borderBottom:
                  language === 'de' ? '1px solid currentColor' : 'none',
                '&:hover': {
                  color:
                    language === 'de'
                      ? 'tom.textInverted'
                      : 'tom.textHighlight',
                },
                mr: ['6'],
              }}
              language="de"
              to={newPath}
            >
              {t('german')}
            </Link>
          </TextSm>
        </GridHalfFull>
      </Box>
    </Box>
  );
}

export default Footer;
