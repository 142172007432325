import { Divider } from 'juhuui';
import { a } from 'react-spring';

export const AnimatedLine = Divider.with({
  as: a.div,
  borderColor: 'tom.line',
  opacity: '1',
  borderTop: '1px solid',
  marginTop: '0.4rem',
  marginBottom: '0.6rem',
});

export const LineVertical = Divider.with({
  opacity: 1,
  borderLeft: '1px solid tom.line',
});
