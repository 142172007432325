import { graphql, useStaticQuery } from 'gatsby';
import { Link as GLink, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { Box, Divider, Flex, Icon, Link, Stack } from 'juhuui';
// import React, { useEffect } from 'react';
import React from 'react';
import { animated, config, useSpring } from 'react-spring';

// import { useScroll } from 'react-use-gesture';
import Button from '../components/button';
import Line from '../components/line';
import { activeLinkClass, grid12, MediumBright } from '../styles';
import { getLocalizationObj } from '../utilities/getLocalizationObj';

// import { window } from '../utilities';

const AnimatedMediumBright = Box.as(animated.div).merge(MediumBright).with();

const query = graphql`
  query HeaderQuery {
    strapi {
      fact {
        localizations {
          bookingLink
          description
        }
        bookingLink
        description
      }
    }
  }
`;

const Header = ({ siteTitle }) => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const data = useStaticQuery(query);
  const { bookingLink, description } =
    getLocalizationObj(data.strapi.fact, language) || {};

  return (
    <AnimatedMediumBright
      // as="header"
      css={{
        bg: 'none',
        position: ['relative', undefined, 'sticky'],
        top: [undefined, undefined, '0'],
        w: 'full',
        zIndex: ['4', undefined, '1'],
      }}
    >
      <Box
        className={`header ${grid12}`}
        css={{
          alignItems: 'center',
          column: '1 /span 12',
          margin: 'auto',
          maxW: 'var(--max-width)',
          pt: ['5', undefined, '1.1rem'],
          px: 'var(--px)',
          top: ['0'],
          w: 'full',
        }}
      >
        <Link
          as={GLink}
          css={{
            column: ['1/span 12', '1/span 7', '1/span 6'],
            fontSize: ['md'],
            d: 'flex',
            align: 'center',
            justify: ['space-between', 'flex-start', undefined],
          }}
          to="/"
        >
          <div>
            <Icon
              className="link-icon"
              css={{
                h: ['1.8rem', '1.6rem', undefined],
                w: ['10rem', '9rem', undefined],
                flexShrink: '0',
                p: 0,
                m: [
                  '0 0.4rem 0.1rem 0',
                  '0 1rem 0.2rem 0',
                  '0 1.4rem 0.2rem 0',
                ],
              }}
              name="logo"
              title="mikro makro"
            />
          </div>
          <Box
            as="div"
            css={{
              textAlign: ['right', 'left', undefined],
              fontSize: ['xxs', undefined, 'xs'],
            }}
          >
            {description}
          </Box>
        </Link>
        <Flex
          as="nav"
          css={{
            column: ['1/span 12', '9/span 4', '7/span 4'],
            fontSize: ['md', undefined, 'md'],
            justify: ['flex-start', 'flex-end', 'flex-start'],
            mt: ['5', '0', undefined],
            lineHeight: '1.6',
          }}
        >
          {/* SERVICES Link */}
          <Link
            activeClassName={activeLinkClass}
            animate="true"
            as={GLink}
            css={{
              '&:hover': { color: 'tom.textHighlight' },
              mr: ['4', undefined, '6'],
              transition: 'color 0.15s ease',
            }}
            to={`/${t('services').toLocaleLowerCase()}/`}
          >
            {t('services')}
          </Link>

          {/* PRACTICE Link */}
          <Link
            fun
            activeClassName={activeLinkClass}
            as={GLink}
            css={{
              '&:hover': { color: 'tom.textHighlight' },
              mr: ['4', undefined, '6'],
              transition: 'color 0.15s ease',
            }}
            to={`/${t('practice link').toLocaleLowerCase()}/`}
          >
            {t('practice')}
          </Link>

          {/* CONTACT Link */}
          <Link
            activeClassName={activeLinkClass}
            as={GLink}
            css={{
              '&:hover': { color: 'tom.textHighlight' },
              mr: ['4', undefined, '6'],
              transition: 'color 0.15s ease',
            }}
            to={`/${t('contact').toLocaleLowerCase()}/`}
          >
            {t('contact')}
          </Link>

          {/* CAREERS Link */}
          <Link
            activeClassName={activeLinkClass}
            as={GLink}
            css={{
              '&:hover': { color: 'tom.textHighlight' },
              left: '-var(--gutter-width)',
              transition: 'color 0.15s ease',
            }}
            to={`/${t('careers').toLocaleLowerCase()}/`}
          >
            {t('careers')}
          </Link>
        </Flex>
        <Flex
          css={{
            column: [undefined, undefined, '11/span 2'],
            display: 'flex',
            position: ['fixed', undefined, 'relative'],
            bottom: ['6vh', undefined, 'auto'],
            right: ['var(--px)', undefined, 'auto'],
            fontSize: ['sm'],
            justify: 'flex-end',
          }}
        >
          <Button
            alt={t('book an appointment online at Mikro Makro')}
            css={{
              boxShadow: ['0 0 0.8rem 0 rgba(0,0,0,0.2)', 'none', undefined],
            }}
            href={bookingLink}
            rel="noopener"
            target="_blank"
            text={t('appointment')}
          />
        </Flex>
        <Line
          css={{
            column: '1/span 12',
            mb: '0',
            mt: ['3', undefined, '4'],
          }}
        />
      </Box>
    </AnimatedMediumBright>
  );
};

export default Header;
